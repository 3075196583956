<template>
  <div class="price-spoiler" :class="{ active: visible }" @click="visible = !visible">
    <button class="price-spoiler__btn">
      <div class="price-spoiler__name-wrapper">
        <span class="price-spoiler__name">{{ title }}</span>
        <span class="price-spoiler__price">От {{ price }}</span>
      </div>
      <NuxtIcon class="icon" :name="visible ? 'IconMinus' : 'IconPlus'" />
    </button>
    <transition
      name="spoiler"
      mode="in-out"
      @enter="enter"
      @leave="leave"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
    >
      <div v-if="visible" class="price-spoiler__content">
        <CTextBlock :text="text" />
        <div
          v-for="(item, index) in services"
          :key="`${index}_price_${item.id}`"
          class="price-spoiler__service"
        >
          <span class="price-spoiler__sub-name"> {{ item.title }} </span>
          <span v-if="item.price" class="price-spoiler__sub-price">{{ item.price }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import CTextBlock from '~/components/blocks/textBlock/CTextBlock.vue';
import type { BlockPricesServicesItem } from '~/types/pages';
import { ref } from '#imports';

interface Props {
  title: string;
  price: string;
  text: string;
  services: BlockPricesServicesItem[];
  index: number;
}

const props = defineProps<Props>();

const visible = ref(false);

if (props.index === 0) {
  visible.value = true;
}

const beforeEnter = (el: HTMLElement) => {
  el.style.opacity = '0';
  el.style.height = '0';
};
const enter = (el: HTMLElement) => {
  el.style.height = `${el.scrollHeight}px`;
  el.style.opacity = '1';
};
const beforeLeave = (el: HTMLElement) => {
  el.style.height = `${el.scrollHeight}px`;
  el.style.opacity = '1';
};
const leave = (el: HTMLElement) => {
  el.style.opacity = '0';
  el.style.height = '0';
};
</script>

<style scoped></style>
